/* eslint-disable import/prefer-default-export */
import { AxiosInstance } from 'axios';
import { ResolvedTenentReadModel } from '@digital.jit/services/tenant/ui-models';
import { genFirebaseAuthToken } from '@digital.jit/seed-work/utils';
import { generateFirebaseQueryUrl } from '../utils';

class TenantPersisted {
  public id = '';

  public companyName = '';

  public createdDate = '';

  public creatorUserId = '';

  public oktaGroupSuffix = '';

  public phoneNumber = '';

  public subdomain = '';

  public tenantIDPId = '';
}

export type FirebaseTenantsResponse = Record<string, TenantPersisted>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const flatten = <T>(arr: T[][]): T[] => ([] as T[]).concat(...arr);

export const resolveTenant = async (
  tenantIDPId: string,
  restClient: AxiosInstance,
): Promise<ResolvedTenentReadModel | undefined> => {
  try {
    const token: string | null = await genFirebaseAuthToken(
      process.env.FB_SERVICE_ACCOUNT_KEY || '',
    );
    if (!token) {
      return undefined;
    }

    const { data } = await restClient.get<FirebaseTenantsResponse>(
      generateFirebaseQueryUrl('tenantIDPid', tenantIDPId, token),
    );

    const tenantValue = Object.values(data)[0] || undefined;
    if (!tenantValue) {
      return undefined;
    }
    return ({
      ...tenantValue,
      id: Object.keys(data)[0],
    } as unknown) as ResolvedTenentReadModel;
  } catch (e) {
    console.log('error in resolveTenant jwt guard', { ...(e as Error) });
    throw e;
  }
};

export const resolveTenantByCreatorId = async (
  creatorId: string,
  restClient: AxiosInstance,
): Promise<ResolvedTenentReadModel[]> => {
  try {
    const token: string | null = await genFirebaseAuthToken(
      process.env.FB_SERVICE_ACCOUNT_KEY || '',
    );

    if (!token) {
      return [];
    }

    const { data } = await restClient.get<FirebaseTenantsResponse>(
      generateFirebaseQueryUrl('creatorUserId', creatorId, token),
    );

    const tenantValue = Object.values(data)[0] || {};
    const tenant = {
      ...tenantValue,
      id: Object.keys(data)[0],
    } as ResolvedTenentReadModel;
    return [tenant];
  } catch (e) {
    console.log('error in resolveTenant jwt guard', { ...(e as Error) });
    throw e;
  }
};

export const resolveTenantBySubdomain = async (
  subdomain: string,
  restClient: AxiosInstance,
): Promise<ResolvedTenentReadModel[]> => {
  try {
    const token: string | null = await genFirebaseAuthToken(
      process.env.FB_SERVICE_ACCOUNT_KEY || '',
    );

    if (!token) {
      return [];
    }

    const { data } = await restClient.get<FirebaseTenantsResponse>(
      generateFirebaseQueryUrl('subdomain', subdomain, token),
    );

    const tenantValue = Object.values(data)[0] || {};
    const tenant = {
      ...tenantValue,
      id: Object.keys(data)[0],
    } as ResolvedTenentReadModel;
    return [tenant];
  } catch (e) {
    console.log('error in resolveTenant jwt guard', { ...(e as Error) });
    throw e;
  }
};
