import TenantGroupPrefixes from './tenant-group-prefixes.enum';
import { IJitSession } from '../../../clients/web/pwa/pages/api/auth/jitSession.interface';

export const genGroupSuffixBySubdomain = (subdomain: string) =>
  subdomain.toUpperCase().replace(/-/gm, '_');

export const genSubdomainByGroupSuffix = (suffix: string) =>
  suffix.toLowerCase().replace(/_/gm, '-');

export const getGroupSuffix = (group: string) =>
  group.replace(
    new RegExp(
      `^(${TenantGroupPrefixes.USERS}|${TenantGroupPrefixes.ADMINS})_`,
    ),
    '',
  );

export const buildUrl = (hostname: string, pathname: string, scheme = '') =>
  `${scheme}//${hostname}${pathname}`;

export const isAssignedToAnyTenant = (session: IJitSession): boolean =>
  !!session?.existingUser?.tenantsIDP?.length;

export const getHost = (url: string): string => {
  const chunks = url.split('://');
  return chunks[chunks.length > 1 ? 1 : 0]; // .replace(/[:/].*/, '');
};

export const getSubdomain = (host: string, hosts: string[]): string =>
  hosts.reduce((acc: string, url: string) => {
    const h = getHost(url);
    if (host.indexOf(`.${h}`) > -1) {
      return host.replace(new RegExp(`.${h}$`), '');
    }
    return acc;
  }, '');

export const getPathnameFromUrl = (url: string) => {
  const host = getHost(url);
  return url.replace(new RegExp(`.*${host}[^/]*`), '');
};

export const buildTenantUri = (
  subdomain: string,
  url: string,
  inputSheme = '',
) => {
  const domain = getHost(url);
  const pathname = getPathnameFromUrl(url);
  let scheme = inputSheme;
  if (!scheme && url.indexOf('://') > -1) {
    scheme = `${url.split('://')[0]}://`;
  }
  return `${scheme}${subdomain}.${domain}${pathname}`;
};

export const buildTenantUrlByTenantItem = (
  tenant: { subdomain: string },
  hostname: string,
  pathname: string,
  scheme = '',
) => `${scheme}//${tenant.subdomain}.${hostname}/${pathname}`;

export const buildTenantHost = (
  subdomain: string,
  tenantHost: string,
  pathname = '/',
) => {
  const { host: tenantHostname, protocol: scheme } = new URL(tenantHost);
  return buildUrl(`${subdomain}.${tenantHostname}`, pathname, scheme);
};
