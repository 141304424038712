import { createContext, Dispatch } from 'react';
import Cookies from 'universal-cookie';
import { nextAuthCookies } from '../helpers/auth';
import { IJitSession } from '../../pages/api/auth/jitSession.interface';

export const sessionCookies = new Cookies();

const spliceSession = (session: IJitSession) => {
  const newSession = {
    existingUser: undefined,
    user: undefined,
    tenant: undefined,
    token: undefined,
    newTenant: undefined,
  };
  if (session?.user) {
    const { user } = session;
    newSession.user = {
      tenantId: user?.tenantId,
      stsTokenManager: {
        accessToken: user.stsTokenManager.accessToken,
        refreshToken: user.stsTokenManager.refreshToken,
      },
    };
  }
  if (session?.existingUser) {
    const { existingUser } = session;
    newSession.existingUser = {
      owner: existingUser.owner,
      tenantsIDP: existingUser.tenantsIDP,
    };
  }
  if (session?.tenant) {
    const { companyName, oktaGroupSuffix, subdomain } = session.tenant;
    newSession.tenant = {
      companyName,
      subdomain,
      oktaGroupSuffix,
    };
  }
  if (session?.token) {
    newSession.token = session.token;
  }
  if (session?.newTenant) {
    newSession.newTenant = session.newTenant;
  }
  return newSession;
};

export const setSessionCookies = (session: IJitSession) => {
  const newSession = session ? spliceSession(session) : session;
  sessionCookies.set(nextAuthCookies.name, newSession, nextAuthCookies.options);
};

export const removeSessionCookies = () => {
  sessionCookies.remove(nextAuthCookies.name, nextAuthCookies.options);
};

export const getSessionCookie = (): IJitSession | undefined => {
  const sessionCookie = sessionCookies.get(nextAuthCookies.name) as string;
  if (!sessionCookie) {
    return undefined;
  }
  if (typeof sessionCookie === 'string') {
    return JSON.parse(sessionCookie) as IJitSession;
  }
  return sessionCookie;
};
export const SessionContext = createContext<{
  session: IJitSession;
  setSession: Dispatch<IJitSession>;
}>({
  session: null,
  setSession: null,
});
