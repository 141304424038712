import getConfig from 'next/config';
import firebase from 'firebase';
import { firebaseCommandUrl } from '@digital.jit/services/customer/ui-models';
import { FirebaseConfig } from '../../../../../../firebase.config';
import { JitNextConfig } from '../../types/jit-next-config.interface';
import { ICommandResponse } from '../../shared/interfaces';
import { getSessionCookie } from '../../shared/context/session';

class FirebaseApi {
  public readonly localApp: firebase.app.App;

  private readonly firebaseInstance: firebase.database.Database;

  private readonly firebaseCommandRegisterUrl: string;

  private isUserAuthenticated: boolean;

  constructor() {
    const { publicRuntimeConfig } = getConfig() as JitNextConfig;
    const config = new FirebaseConfig({
      FB_API_KEY: publicRuntimeConfig.FB_API_KEY,
      FB_AUTH_DOMAIN: publicRuntimeConfig.FB_AUTH_DOMAIN,
      FB_RTDB_URL: publicRuntimeConfig.FB_RTDB_URL,
      FB_PROJECT_ID: publicRuntimeConfig.FB_PROJECT_ID,
      FB_SERVICE_ACCOUNT_KEY: publicRuntimeConfig.FB_SERVICE_ACCOUNT_KEY,
    } as any);
    this.firebaseCommandRegisterUrl = firebaseCommandUrl(
      publicRuntimeConfig as any,
    );

    this.localApp = firebase.apps.find(
      (app) => app.name === config.databaseURL,
    );
    if (!this.localApp) {
      this.localApp = firebase.initializeApp(config, config.databaseURL);
    }

    this.firebaseInstance = this.localApp.database(
      this.firebaseCommandRegisterUrl,
    );

    this.userAuthStatus = !!this.localApp.auth().currentUser;
  }

  get userAuthStatus() {
    return this.isUserAuthenticated;
  }

  set userAuthStatus(status) {
    this.isUserAuthenticated = status;
  }

  getCommandSubscription(userId: string) {
    return this.firebaseInstance
      .ref(`command/${userId}`)
      .orderByChild('read')
      .equalTo(false);
  }

  async updateCommand(commands: ICommandResponse[], userId: string) {
    const listCommandId = commands.map((c) =>
      this.firebaseInstance.ref(`command/${userId}/${c.id}`).update({
        read: true,
      }),
    );
    await Promise.all(listCommandId);
  }

  signIn = async (
    token: string,
    tenantId: string = null,
  ): Promise<firebase.auth.UserCredential> => {
    try {
      const auth = this.localApp.auth();
      const session = getSessionCookie();
      auth.tenantId =
        !tenantId && session?.user?.tenantId
          ? session?.user?.tenantId
          : tenantId;
      auth.tenantId =
        !tenantId && session?.user?.tenantId
          ? session?.user?.tenantId
          : tenantId;

      const data = await auth.signInWithCustomToken(token);
      this.userAuthStatus = !!this.localApp.auth().currentUser;
      return data;
    } catch (error) {
      const errorCode = (error as firebase.auth.Error).code;
      if (errorCode === 'auth/id-token-expired') {
        const newToken = await this.refreshToken();
        if (newToken) {
          await this.signIn(newToken);
        }
      }
    }
  };

  async logOut() {
    if (this.isUserAuthenticated) {
      await this.localApp.auth().signOut();
      this.userAuthStatus = !!this.localApp.auth().currentUser;
    }
  }

  async refreshToken(): Promise<string> {
    if (this.isUserAuthenticated) {
      const {
        token,
      } = await this.localApp.auth()?.currentUser.getIdTokenResult(true);
      return token;
    }
    return null;
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string,
    tenantIDPid: string = null,
  ): Promise<firebase.auth.UserCredential> {
    const session = getSessionCookie();
    this.localApp.auth().tenantId =
      !tenantIDPid && session?.user?.tenantId
        ? session?.user?.tenantId
        : tenantIDPid;

    return this.localApp.auth().signInWithEmailAndPassword(email, password);
  }

  getValidatedUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = this.localApp.auth().onAuthStateChanged((user) => {
        const session = getSessionCookie();
        if (typeof window === 'undefined') {
          unsubscribe();
          return null;
        }
        if (user) {
          resolve(user);
          unsubscribe();
        } else if (!session?.user && !user && typeof window !== 'undefined') {
          reject('Your not signed in');
          unsubscribe();
        }
      }, reject);
    });
  }
}

export const FirebaseApiInstance = new FirebaseApi();
