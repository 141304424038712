export enum EmployeeTimelogParentType {
  TASK = 'TASK',
  TASK_STEP = 'TASK_STEP',
}

export type TimelogEmployeeType = {
  firstName: string;
  lastName: string;
  role: string;
  rate: number;
};

export class EmployeeTimelogSeed {
  employeeId = '';

  employee: TimelogEmployeeType | undefined;

  parentType: EmployeeTimelogParentType | undefined;

  parentId = '';

  actualSpendTime = 0;

  estimatedTime = 0;

  fullCost = 0;

  rate = 0;

  costOfParent = 0;
}
