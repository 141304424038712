import slugify from 'slugify';

interface FlavoredAlphabetMap {
  [key: string]: { [key: string]: string };
}

class Transliterate {
  static flavoredAlphabetMaps: FlavoredAlphabetMap = {
    bg: {
      а: 'a',
      б: 'b',
      в: 'v',
      г: 'g',
      д: 'd',
      е: 'e',
      ж: 'j',
      з: 'z',
      и: 'i',
      й: 'y',
      к: 'k',
      л: 'l',
      м: 'm',
      н: 'n',
      о: 'o',
      п: 'p',
      р: 'r',
      с: 's',
      т: 't',
      у: 'u',
      ф: 'f',
      х: 'h',
      ц: 'c',
      ч: 'ch',
      ш: 'sh',
      щ: 'sht',
      ъ: 'a',
      ь: 'y',
      ю: 'yu',
      я: 'ya',
      ё: 'yo',
      ы: 'ay',
      э: 'e',
    },
    ru: {
      а: 'a',
      б: 'b',
      в: 'v',
      г: 'g',
      д: 'd',
      е: 'e',
      ж: 'zh',
      з: 'z',
      и: 'i',
      й: 'i',
      к: 'k',
      л: 'l',
      м: 'm',
      н: 'n',
      о: 'o',
      п: 'p',
      р: 'r',
      с: 's',
      т: 't',
      у: 'u',
      ф: 'f',
      х: 'h',
      ц: 'c',
      ч: 'ch',
      ш: 'sh',
      щ: 'sht',
      ъ: 'a',
      ь: 'y',
      ю: 'yu',
      я: 'ya',
      ё: 'yo',
      ы: 'i',
      э: 'e',
    },
    uk: {
      а: 'a',
      б: 'b',
      в: 'v',
      г: 'g',
      д: 'd',
      е: 'e',
      ж: 'zh',
      з: 'z',
      и: 'i',
      й: 'i',
      к: 'k',
      л: 'l',
      м: 'm',
      н: 'n',
      о: 'o',
      п: 'p',
      р: 'r',
      с: 's',
      т: 't',
      у: 'u',
      ф: 'f',
      х: 'h',
      ц: 'c',
      ч: 'ch',
      ш: 'sh',
      щ: 'sht',
      ъ: 'a',
      ь: 'y',
      ю: 'yu',
      я: 'ya',
      ё: 'yo',
      ы: 'i',
      э: 'e',
    },
  };

  constructor(protected flavor: string) {
    Transliterate.checkFlavor(flavor);
  }

  private static checkFlavor(flavor: string) {
    if (!Transliterate.flavoredAlphabetMaps[flavor]) {
      // console.error('No transliteragion map exists for the provided flavour');
      return false;
    }
    return true;
  }

  setFlavor(flavor: string) {
    if (Transliterate.checkFlavor(flavor)) {
      this.flavor = flavor;
    }
  }

  transliterate(str: string) {
    return Transliterate.value(str, this.flavor);
  }

  slugify(str: string) {
    return Transliterate.slugify(str, this.flavor);
  }

  static value(str: string, flavor: string) {
    const alphabetMap = Transliterate.checkFlavor(flavor)
      ? Transliterate.flavoredAlphabetMaps[flavor]
      : {};
    return [...str].map((char) => alphabetMap[char] || char).join('');
  }

  static slugify(str: string, flavor: string) {
    return slugify(Transliterate.value(str, flavor), { lower: true });
  }
}

export default Transliterate;
