// eslint-disable-next-line import/prefer-default-export
export const createExcludePropsArray = (
  o,
  globalExclude = '',
  defaultValue = [],
) => {
  const max = 1500;
  if (!o || !(o instanceof Object)) {
    return [];
  }
  const excluded = Object.entries(o).reduce((acc, [key, value]) => {
    if (typeof value === 'string' && value.length > max) {
      if (o.exclude) {
        if (globalExclude) {
          acc.push(`${globalExclude}.${key}`);
        } else {
          acc.push(`${o.exclude}.${key}`);
        }
      } else {
        acc.push(key);
      }
    } else if (Array.isArray(value)) {
      const exclude = !globalExclude ? `${key}[]` : `${globalExclude}.${key}[]`;
      value.forEach((a) => {
        createExcludePropsArray(
          {
            ...a,
            exclude: `${key}[]`,
          },
          exclude,
          acc,
        );
      });
      globalExclude = `${key}[]`;
    }
    return acc;
  }, defaultValue);
  return [...new Set(excluded)];
};
