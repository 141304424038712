import getConfig from 'next/config';
import { JitNextConfig } from '../../../types/jit-next-config.interface';

const { publicRuntimeConfig } = getConfig() as JitNextConfig;

const authUrl = new URL(publicRuntimeConfig.NEXT_PUBLIC_URL);
const useSecureCookies = authUrl.protocol === 'https:';
const cookiePrefix = useSecureCookies ? '__Secure-' : '';
const { hostname } = authUrl;
const cookieHost = `.${hostname.split('.').splice(1).join('.')}`;

export const nextAuthCookies = {
  name: `${cookiePrefix}next-auth.session-token`,
  options: {
    sameSite: 'lax' as const,
    path: '/',
    secure: useSecureCookies,
    domain: cookieHost,
  },
};
