import axios from 'axios';
import getConfig from 'next/config';
import { JitNextConfig } from '../../../types/jit-next-config.interface';
import { resolveTenant } from '../../../../../../auth/src/fullstack/tenant-resolution';

const { serverRuntimeConfig } = getConfig() as JitNextConfig;

export const queryTenants = async (tenantId: string) => {
  try {
    const axiosForResolution = axios.create({
      baseURL: serverRuntimeConfig.TENANTS_COLLECTION_REF,
    });
    return await resolveTenant(tenantId, axiosForResolution);
  } catch (e) {
    console.error('Error when trying to fetch the tenants upon signin:', e);
  }
};
