import * as gapi from 'googleapis';

interface ShortServiceAccount {
  client_email: string;
  private_key: string;
}

// The Service Account JSON token can be retrieved by an env var.
export default (
  serviceAccountJson: string,
  scopes = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/firebase.database',
    'https://www.googleapis.com/auth/cloud-platform',
  ],
): Promise<string | null> => {
  let serviceAccount: ShortServiceAccount;
  return new Promise((resolve, reject) => {
    try {
      serviceAccount = JSON.parse(serviceAccountJson) as ShortServiceAccount;
    } catch (e) {
      const state = 'Failed to parse json string';
      console.error(state);
      reject(state);
    }

    // Authenticate a JWT client with the service account.
    const jwtClient = new gapi.google.auth.JWT(
      serviceAccount.client_email,
      null,
      serviceAccount.private_key,
      scopes,
    );

    // Use the JWT client to generate an access token.
    jwtClient.authorize((error, tokens) => {
      if (error) {
        console.log('Error making request to generate access token:', error);
        reject(error);
      }

      if (tokens.access_token === null) {
        const state =
          'Provided service account does not have permission to generate access tokens';
        console.error(state);
        reject(state);
      }
      resolve(tokens.access_token);
    });
  });
};
