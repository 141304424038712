export const createServiceInitialValues = {
  address: '',
  addressCustomer: '',
  companyName: '',
  customerId: '',
  deliveryDate: '',
  description: '',
  email: '',
  name: '',
  serviceType: '',
  startDate: '',
  surname: '',
  telephone: '',
  phoneNumber: '',
  serviceManager: '',
};

export default createServiceInitialValues;
