import { SigninInitialProps } from '@digital.jit/services/tenant/ui-models';
import { UserGetByEmail } from '@digital.jit/contacts';
import { AddToast } from 'react-toast-notifications';
import { FormikHelpers, FormikValues } from 'formik';
import { TFunction } from 'i18next';
import { FirebaseApiInstance } from '../../../api/firebase';
import { IError } from '../../interfaces';
import {
  IFirebaseUser,
  IJitSession,
} from '../../../pages/api/auth/jitSession.interface';
import { setSessionCookies } from '../../context/session';
import { signInInitialValues } from '../../formik-initial-values';

export const signInUserInDefaultTenant = async (
  values: SigninInitialProps,
  user: UserGetByEmail.Response,
): Promise<IFirebaseUser> => {
  const {
    user: signedInUser,
  } = await FirebaseApiInstance.signInWithEmailAndPassword(
    values.email,
    values.password,
    user?.owner ? null : user?.tenantsIDP[0],
  );
  return signedInUser.toJSON() as IFirebaseUser;
};

export const getSignInErrorsObject = (
  error: IError,
  toastCallback: AddToast,
  t: TFunction,
) => {
  let errorsObject = {};
  const { errors } = error?.response?.data ?? {};
  if (errors?.length) {
    errors.forEach((err) => {
      errorsObject = {
        ...errorsObject,
        [err.property]: t(`formErrors.user.${err.message}`),
      };
    });
  }

  if (error?.code === 'auth/wrong-password') {
    errorsObject = {
      ...errorsObject,
      password: t(`formErrors.user.${error.message}`),
    };
    toastCallback(t(`formErrors.user.${error.message}`), {
      appearance: 'error',
      autoDismiss: true,
    });
  } else if (error?.code === 'auth/too-many-requests') {
    toastCallback(error.message, {
      appearance: 'error',
      autoDismiss: true,
    });
  }
  return errorsObject;
};

export const resetFormAndSetCookies = (
  sessionCookies: IJitSession,
  { resetForm, setValues }: FormikHelpers<FormikValues>,
) => {
  setValues(signInInitialValues);
  resetForm();
  setSessionCookies(sessionCookies);
};
