import IFirebaseConfig from './firebase-config.interface';

export class FirebaseConfig implements IFirebaseConfig {
  apiKey: string;
  storageBucket = 'bucket.appspot.com';
  authDomain: string;
  databaseURL: string;
  projectId: string;
  serviceAccount: string;

  // messagingSenderId: process.env.FIREBASE_MESSAGE_ID;
  constructor(env: NodeJS.ProcessEnv) {
    this.apiKey = env.FB_API_KEY as string;
    this.authDomain = env.FB_AUTH_DOMAIN as string;
    this.databaseURL = env.FB_RTDB_URL as string;
    this.projectId = env.FB_PROJECT_ID as string;
    this.serviceAccount = env.FB_SERVICE_ACCOUNT_KEY as string;
  }
}
