import axios from 'axios';
import genFirebaseAuthToken from './firebaseAuthToken';

interface IFirebaseRules {
  createCustomerDatabaseRules(url: string): Promise<void>;
}

class FirebaseRules implements IFirebaseRules {
  private readonly FB_DEFAULT_RULES = {
    rules: {
      '.read': false,
      '.write': false,
      services: {
        '.indexOn': ['customerId'],
      },
      tasks: {
        '.indexOn': ['created', 'installer', 'executor'],
      },
      'task-steps': {
        $id: {
          '.indexOn': ['positionIndex'],
        },
      },
      reports: {
        timelog: {
          employee: {
            '.indexOn': ['created'],
          },
        },
      },
    },
  };

  async createCustomerDatabaseRules(databasePrefix: string) {
    const tokenForRules: string | null = await genFirebaseAuthToken(
      process.env.FB_SERVICE_ACCOUNT_KEY || '',
    );
    try {
      const addIndexUrl = `${databasePrefix}/.settings/rules.json?access_token=${tokenForRules}`;
      await axios.put(addIndexUrl, this.FB_DEFAULT_RULES);
    } catch (e) {
      console.error('Error createCustomerDatabaseRules: ', e);
      throw e;
    }
  }
}

export default new FirebaseRules();
