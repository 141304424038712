/* eslint-disable import/prefer-default-export */
import { ConfigLike } from './types';
import { buildUrl } from '../fullstack/utils';
import { IJitSession } from '../../../clients/web/pwa/pages/api/auth/jitSession.interface';

export const tenantRedirectTo = (
  curUrl: string,
  { publicRuntimeConfig }: ConfigLike,
  session?: IJitSession,
) => {
  const chooseTenantPathname =
    publicRuntimeConfig?.chooseTenant || '/auth/select-your-organization';
  const signInPathname = publicRuntimeConfig.signIn;
  const currentUrl = new URL(curUrl);
  const loginHostname = new URL(publicRuntimeConfig.NEXT_PUBLIC_LOGIN_URL).host;
  const tenantHostname = new URL(publicRuntimeConfig.NEXT_PUBLIC_URL).host;
  const signinErrorPathname = publicRuntimeConfig.signinError;

  const createTenantPathname = publicRuntimeConfig.createTenant;
  const currentHostname = currentUrl.host;
  const currentPathname = currentUrl.pathname;
  const scheme = currentUrl.protocol;

  const isOnTenantHost = currentHostname.endsWith(tenantHostname);
  const tenantSubdomainFromUrl =
    (isOnTenantHost && currentHostname.replace(`.${tenantHostname}`, '')) || '';

  if (session?.user?.tenantId && session?.tenant) {
    const { tenant } = session;

    const resolvedSubdomain = tenant?.subdomain;
    const tenantSubdomain = resolvedSubdomain || tenantSubdomainFromUrl;
    const computedTenantHostname =
      (tenantSubdomain && `${tenantSubdomain}.${tenantHostname}`) || '';
    let nextPage = '/';

    if (currentPathname !== signInPathname) {
      if (currentPathname !== chooseTenantPathname && !session?.newTenant) {
        nextPage = currentPathname;
      } else if (session?.newTenant) {
        nextPage = '/tenant/plans';
      } else {
        nextPage = '/dashboard';
      }
    }
    console.log('navigate to tenant dashboard', nextPage);

    return buildUrl(computedTenantHostname, nextPage, scheme);
  }
  if (session?.user?.tenantId && !session?.tenant) {
    console.log('navigate to error tenant');
    return buildUrl(
      loginHostname,
      `${signinErrorPathname}?error=no-tenants`,
      scheme,
    );
  }
  if (
    session &&
    'existingUser' in session &&
    session?.existingUser?.tenantsIDP?.length &&
    session?.existingUser?.tenantsIDP?.length > 1
  ) {
    console.log('navigate to select tenant');
    return buildUrl(loginHostname, chooseTenantPathname, scheme);
  }
  if (session?.user) {
    console.log('navigate to create tenant');
    return buildUrl(loginHostname, createTenantPathname, scheme);
  }
  console.log('navigate to default');
  return buildUrl(loginHostname, signInPathname, scheme);
};
