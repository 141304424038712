import firebase from 'firebase';
import getConfig from 'next/config';
import { v4 as uuid } from 'uuid';
import { JitNextConfig } from '../../types/jit-next-config.interface';
import { FirebaseConfig } from '../../../../../../firebase.config';
import { FirebaseFolderName } from '../../shared/helpers';
import { IUploadFile } from '../../shared/interfaces';

class Storage {
  private readonly localApp: firebase.app.App;

  constructor() {
    const { publicRuntimeConfig } = getConfig() as JitNextConfig;
    const config = new FirebaseConfig({
      FB_API_KEY: publicRuntimeConfig.FB_API_KEY,
      FB_AUTH_DOMAIN: publicRuntimeConfig.FB_AUTH_DOMAIN,
      FB_RTDB_URL: publicRuntimeConfig.FB_RTDB_URL,
      FB_PROJECT_ID: publicRuntimeConfig.FB_PROJECT_ID,
      FB_SERVICE_ACCOUNT_KEY: publicRuntimeConfig.FB_SERVICE_ACCOUNT_KEY,
    } as any);
    this.localApp = firebase.apps.find(
      (app) => app.name === config.databaseURL,
    );
    if (!this.localApp) {
      this.localApp = firebase.initializeApp(config, config.databaseURL);
    }
  }

  async uploadSingleFile(
    folderName: FirebaseFolderName,
    file: File,
    bucketName: string,
  ): Promise<IUploadFile> {
    const uniqueImageName = `${uuid()}-${file.name}`;

    const storageRef = this.localApp.storage(bucketName).ref();

    const { ref } = await storageRef
      .child(`${folderName}/${uniqueImageName}`)
      .put(file);
    return {
      name: uniqueImageName,
      url: await ref.getDownloadURL(),
    };
  }

  async uploadFiles(
    folderName: FirebaseFolderName,
    files: File[],
    bucketName: string,
  ) {
    try {
      const filesToUpload = files.map((file) =>
        this.uploadSingleFile(folderName, file, bucketName),
      );
      return await Promise.all(filesToUpload);
    } catch (e) {
      console.log({ ...e });
      throw e;
    }
  }
}

export const FirebaseStorage = new Storage();
